<template>
  <v-app id="app">
    <notifications
      group="errors"
      class="notifications"
      :duration="-1"
      :ignoreDuplicates="true"
      :max="1"
    ></notifications>
    <notifications
      group="notices"
      class="notifications"
      :ignoreDuplicates="true"
      :max="1"
    ></notifications>
    <loader-overlay></loader-overlay>

    <v-app-bar
      app
      dense
      flat
    >

      <v-menu
        bottom
        nudge-bottom
        offset-y
        min-width="150"
        :disabled="!isLoggedIn"
      >
        <template v-slot:activator="{ on }">
          <v-app-bar-nav-icon v-on="on"></v-app-bar-nav-icon>
        </template>

        <v-list>
          <v-divider></v-divider>
          <v-list-item to="/wagers">Wagers</v-list-item>
          <v-divider></v-divider>
          <v-list-item to="/games">Games</v-list-item>
          <v-divider></v-divider>
          <v-list-item to="/progress-summary">Progress Summary</v-list-item>
        </v-list>
      </v-menu>

      <v-toolbar-title>Vig Admin</v-toolbar-title>

      <v-spacer></v-spacer>

      <v-btn
        v-if="isLoggedIn"
        dense
        small
        color="primary"
        @click="logout"
      >
        Log Out
      </v-btn>
    </v-app-bar>

    <v-main>
      <router-view @updatelogin="key++" />
    </v-main>
  </v-app>
</template>

<script>
import UserService from '@/services/UserService'

export default {
  name: 'app',
  data: () => {
    return {
      key: 0
    }
  },
  computed: {
    isLoggedIn () {
      return UserService.loggedIn(this.key)
    }
  },
  methods: {
    logout () {
      UserService.logout()
      this.key--
      return UserService.redirectToLogin()
    }
  }
}
</script>

<style lang="scss">
#app {
  font-family: Avenir, Helvetica, Arial, sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  text-align: center;
  color: #2c3e50;
}

#nav {
  padding: 30px;

  a {
    font-weight: bold;
    color: #2c3e50;

    &.router-link-exact-active {
      color: #42b983;
    }
  }
}
</style>
